export default {
  /**
   * Get cookie value.
   *
   * @param cookieName cookie name
   * @returns {string} cookie value
   */
  getCookie(cookieName: string): string {
    let i;
    let name = cookieName + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  },

  /**
   * Set cookie with given value
   * @param {string} cookieName
   * @param {string} cookieValue
   * @param {Date} expires
   * @param {string} domain
   * @param {boolean} secure
   */
  setCookie(cookieName: string, cookieValue: string, expires?: Date, domain?: string, secure?: boolean) {
    const DEFAULT_EXPIRED = new Date(2100, 1, 1);
    const DEFAULT_DOMAIN = ((domain) => {
      const _domain = domain.split('.');
      return (_domain.length !== 3 && _domain.length !== 4) ?
        domain : domain.replace(/^(?:[\w\d]+\.)?([^:/]*).*$/i, "$1");
    })(decodeURIComponent(document.domain));
    let cookieString = [];

    cookieString.push(`${cookieName}=${encodeURIComponent(cookieValue)}`);
    cookieString.push(`; expires=${expires && expires.toUTCString() || DEFAULT_EXPIRED.toUTCString()}`);
    cookieString.push(`; domain=${domain || DEFAULT_DOMAIN}`);
    cookieString.push(secure && `; secure`);

    document.cookie = cookieString.join("");
  }
}