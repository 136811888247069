import './polyfill';
import WebLogRequester from './requesters/WebLogRequester';
import AppLogRequester from './requesters/AppLogRequester';
import CommonParameter from './parameters/CommonParameter';
import MetaParameter from './parameters/MetaParameter';
import DataParameter from './parameters/DataParameter';
import ExtraParameter from './parameters/ExtraParameter';
import CampaignParameter from './parameters/CampaignParameter';
import ParameterStore from './parameters/ParameterStore';
import AttrDataUtil from './utils/AttrDataUtil';
import WindowEventListener from './utils/WindowEventListener';
import ConsoleLog from './utils/ConsoleLog';
import TTILog from './modules/TTILog/TTILog';
import ConfigResolver from './config/ConfigResolver';
import FCPLog from './modules/FCPLog/FCPLog';
import FMPLog from './modules/FMPLog/FMPLog';
import PageViewLog from './modules/PageViewLog/PageViewLog';
import LCPLog from './modules/LCPLog/LCPLog';
import FIDLog from './modules/FIDLog/FIDLog';
import LogInterceptor from './requesters/LogInterceptor';

const TAG = 'WebLog';
const SCRIPT_TAG_SELECTOR = 'coupang-web-log';
const AUTO_PAGEVIEW_ELEMENT_SELECTOR = '[data-web-log-event=pageview]';
const AUTO_BIND_EVENT_TYPES = ['click', 'mouseenter', 'mouseleave', 'hold', 'scroll', 'swipe', 'keyup', 'keydown'];
const INIT_ATTRIBUTE_NAMES = {
  APP_CODE:               'data-app-code',
  HOST:                   'data-host',
  PLATFORM:               'data-platform',
  MARKET:                 'data-market',
  SERVICE:                'data-service',
  MODE:                   'data-mode',
  AUTO_SEND_PAGEVIEW_LOG: 'data-auto-send-pageview',
  PRIVATE_ELB:            'data-private-elb',
  TTI:                    'data-tti',
  V2_API:                 'data-v2-api',
  FMP:                    'data-fmp',
  AUTO_SEND_SPA_PAGEVIEW: 'data-auto-send-spa-pageview'
};

const PRIVATE_DOMAINS = ['coupang.net', 'coupangdev.com'];

export interface DefaultOptionsType {
  reportSpaPageViewLog?: boolean;
  [key: string]: any;
}

/**
 * This class provides Coupang App, Web, Mobile Web PageView and Event Logging.
 */
export default class WebLog {
  private appCode: string;
  private platform: string;
  private campaignParameter: object;
  private commonParameter: CommonParameter;
  private webLogRequester: WebLogRequester;
  private appLogRequester: AppLogRequester;
  private logInterceptor: LogInterceptor<object>;
  private attrDataUtil: AttrDataUtil;
  private parameterStore: ParameterStore;
  private configResolver: ConfigResolver

  constructor() {
    const scriptEl = document.getElementById(SCRIPT_TAG_SELECTOR);

    if (scriptEl && !window['CoupangWebLog']) {
      const elCustomData = {
        reportSpaPageViewLog: scriptEl.getAttribute(INIT_ATTRIBUTE_NAMES.AUTO_SEND_SPA_PAGEVIEW)
      };

      const initData = {
        appCode: scriptEl.getAttribute(INIT_ATTRIBUTE_NAMES.APP_CODE) || undefined,
        host: scriptEl.getAttribute(INIT_ATTRIBUTE_NAMES.HOST) || undefined,
        platform: scriptEl.getAttribute(INIT_ATTRIBUTE_NAMES.PLATFORM) || '',
        market: scriptEl.getAttribute(INIT_ATTRIBUTE_NAMES.MARKET) || undefined,
        service: scriptEl.getAttribute(INIT_ATTRIBUTE_NAMES.SERVICE) || '',
        // FIXME: can be refactored with const assertion
        mode: (scriptEl.getAttribute(INIT_ATTRIBUTE_NAMES.MODE) || 'production') as Mode,
        useAutoSendPageViewLog: scriptEl.getAttribute(INIT_ATTRIBUTE_NAMES.AUTO_SEND_PAGEVIEW_LOG) === 'true',
        usePrivateELB: scriptEl.getAttribute(INIT_ATTRIBUTE_NAMES.PRIVATE_ELB) || 'auto',
        tti: JSON.parse(scriptEl.getAttribute(INIT_ATTRIBUTE_NAMES.TTI)),
        useV2API: scriptEl.getAttribute(INIT_ATTRIBUTE_NAMES.V2_API) === 'true',
        fmp: scriptEl.getAttribute(INIT_ATTRIBUTE_NAMES.FMP) === 'true',
        reportSpaPageViewLog: elCustomData.reportSpaPageViewLog === null ? true : scriptEl.getAttribute(INIT_ATTRIBUTE_NAMES.AUTO_SEND_SPA_PAGEVIEW) === 'true'
      };
      ConsoleLog.d(TAG,'#constructor - use custom attribute(data-platform, data-service, data-mode, data-auto-send-pageview) for initialize');
      ConsoleLog.d(TAG, `#constructor - initData = ${initData}`,);

      this.init(initData);
    } else {
      ConsoleLog.d(TAG, '#constructor - Not found script tag(#coupang-web-log) for initialize');
    }
  }

  /**
   * Initialize WebLog.
   *
   * @param initData {object} data for initialize
   *          - appCode {string} Specify a application code registered in LJ Portal
   *          - host {string} (Optional) Specify a custom host url. Will ignore the default url generated by 'mode' and 'usePrivateELB' properties. Must starts with 'https://'.
   *          - platform {string} In case of webview,value of the platform parameter is ignored and set automatically according to OS(IOS, ANDROID).
   *              android | ios | web | mweb
   *          - market {string} Specify a market of the service.
   *          - service {string} In case of webview, value of service parameter is ignored and it is determined by Android and iOS Web Log library.
   *              coupang | wing | ...
   *          - mode {string} default value is "production"
   *              develop, integration, production
   *          - tti {object} Configuration of TTILogger
   *          - useAutoSendPageViewLog {boolean} automatically send logs when document DOMContentLoaded (Mozilla, Opera, Webkit), onreadystatechange (IE8) event occurs.
   *          - usePrivateELB {string} default value is "auto" for back-office service, send logs to private ELB instead of public Akamai.
   *              auto, true, false
   *          - useV2API {string} Enable sending request with Lumberjack collector v2 api. Default to "false". Must set 'appCode' and 'market' to use V2 api.
   * @returns {WebLog} instance of WebLog.
   */
  init(initData: InitParams = {
    appCode: 'coupang',
    host: undefined,
    mode: 'production',
    platform: 'web',
    market: 'KR',
    service: 'coupang',
    useAutoSendPageViewLog: false,
    usePrivateELB: 'auto',
    useV2API: false,
    reportSpaPageViewLog: true
  }): WebLog {
    const {
      appCode,
      host,
      mode,
      platform,
      market,
      service,
      useAutoSendPageViewLog,
      usePrivateELB,
      useV2API,
      tti,
      fmp = false,
      reportSpaPageViewLog = true
    } = initData;

    if (useV2API) {
      if (appCode === undefined) {
        throw new Error('appCode param should be specified when using V2 API.');
      } else if (market === undefined) {
        throw new Error('market param should be specified when using V2 API.');
      }
    }

    (mode !== 'production') && ConsoleLog.setDebugMode();
    ConsoleLog.d(TAG, `#init -\n${JSON.stringify(initData, undefined, 2)}`);

    const detectPrivateELB = !usePrivateELB || usePrivateELB.toLowerCase() === 'auto';
    const detected = detectPrivateELB ? this.shouldUsePrivateELB() : (usePrivateELB === 'true');

    this.checkCustomHost(host, detected);

    this.appCode = appCode !== undefined && appCode.length > 0 ? appCode : service;
    this.configResolver = new ConfigResolver({
      appCode: this.appCode,
      host,
      market,
      mode,
      usePrivateELB: detected,
      useV2API,
    });

    this.logInterceptor = new LogInterceptor();
    this.webLogRequester = new WebLogRequester({
      ...this.configResolver.resolveEndpoints(),
    }, this.logInterceptor);
    this.appLogRequester = new AppLogRequester(this.logInterceptor);
    this.attrDataUtil = new AttrDataUtil();
    this.parameterStore = new ParameterStore();
    this.campaignParameter = CampaignParameter.getCampaignParameter();
    this.platform = this.detectPlatform(platform);

    useAutoSendPageViewLog && this.autoSendPageViewLog();
    this.bindEvent(AUTO_BIND_EVENT_TYPES);

    if (tti && !window['ttiSubmit']) {
      const ttiLogger = new TTILog(this.webLogRequester, this.appLogRequester);
      ConsoleLog.d(TAG, '#init - Active \'tti-logger\'');

      ttiLogger.init({
        platform,
        ...tti,
      });

      window['ttiSubmit'] = ttiLogger.manualRecord.bind(ttiLogger);
      window['ttiRecord'] = ttiLogger.record.bind(ttiLogger);
      window['ttiStop'] = ttiLogger.stop.bind(ttiLogger);
      window['logImageLoadTime'] = ttiLogger.logImageLoadTime.bind(ttiLogger);
    }

    try {
      this.initPerfMetrics({
        platform,
        ...tti,
      });

      if (reportSpaPageViewLog) {
        this.initPageViewLog({
          platform,
          ...tti,
        });
      }
    } catch(e) {
      console && console.log && console.log('initPerfMetrics or initPageViewLog error: ', e);
    }

    return this;
  }

  initPerfMetrics(fields: PerfInitFields, shouldInitFMP: boolean = false) {
    [
      {
        name: 'FCP',
        constructor: FCPLog,
      },
      {
        name: 'FMP',
        constructor: FMPLog,
      },
      {
        name: 'LCP',
        constructor: LCPLog,
      },
      {
        name: 'FID',
        constructor: FIDLog,
      },
    ].forEach(({ name, constructor }) => {
      if (shouldInitFMP || name !== 'FMP') {
        const logger = new constructor(this.webLogRequester, this.appLogRequester);
        logger.init(fields);
      }
    });
  }

  /**
   * run handlers before weblog sending
   * @param callback before send, callback is called with returned params
   */
  public useRequestInterceptor(callback: (param: RequestParams) => RequestParams | Promise<RequestParams>){
    this.logInterceptor.useRequestInterceptor(callback);
  }

  initPageViewLog(fields: PerfInitFields) {
    // init PageViewLog
    const pageViewLog = new PageViewLog(this.webLogRequester, this.appLogRequester);
    pageViewLog.init(fields);
  }

  autoSendPageViewLog() {
    const onLoadListener = () => {
      ConsoleLog.d(TAG, '#autoSendPageViewLog - called onLoadListener');

      const pageViewEl = document.querySelector(AUTO_PAGEVIEW_ELEMENT_SELECTOR);
      const requestParam = this.getRequestParam(pageViewEl);
      this.submit(this.parameterStore.replaceDynamicString(requestParam), true);

      window.removeEventListener('load', onLoadListener);
      ConsoleLog.d(TAG, '#autoSendPageViewLog - removeEventListener success');
    };
    WindowEventListener.add('load', onLoadListener);
    ConsoleLog.d(TAG, '#autoSendPageViewLog - addEventListener success');
  }

  /**
   * Register parameter
   * @param registerKey key for request parameter
   * @param jsonData {object} JSON data
   */
  registerParameter(registerKey: string, jsonData: object) {
    this.parameterStore.register(registerKey, jsonData);
  }

  /**
   * Get parameter
   * @param registerKey key for request parameter
   * @returns {object} JSON data
   */
  getRegisteredParameter(registerKey: string): object{
    return this.parameterStore.getRegisteredData(registerKey);
  }

  /**
   * Set event (data-coupang-web-log-event) on the element.
   *
   * @param element element of document
   * @param eventType event type
   */
  setEvent(element: Element, eventType: string) {
    this.attrDataUtil.setEvent(element, eventType);
  }

  /**
   * Get event (data-coupang-web-log-event) defined in element.
   *
   * @param element {Element} element of document
   * @returns {string} event type
   */
  getEvent(element: Element): string {
    return this.attrDataUtil.getEvent(element);
  }

  /**
   * Element to the request parameter (data-coupang-web-log-param).
   *
   * @param element {Element} element of document
   * @param jsonData {object} JSON data
   */
  setRequestParam(element: Element, jsonData: object) {
    this.attrDataUtil.setRequestParam(element, jsonData);
  }

  /**
   * Get the request parameter (data-coupang-web-log-param) defined in the element.
   *
   * @param element {Element} element of document
   * @returns {RequestParams} JSON object for request
   */
  getRequestParam(element: Element): RequestParams|string {
      return this.attrDataUtil.getRequestParam(element);
  }

  /**
   * Send data for PageView or Event Logging.
   *
   * @param params {RequestParams} JSON object for request
   * @param options {object} if PageView log is true, otherwise false. (default value is false)
   */
  submit(params: RequestParams, options: any = false) {
    let isPVLog = false;
    let isAsync = true;

    if (typeof options === "boolean") {
      isPVLog = options
    } else if (options !== null && typeof options === "object") {
      isPVLog = (options.isPVLog) && true || false;
      isAsync = (options.isAsync) && true || false;
    }

    ConsoleLog.d(TAG, `#submit - isPVLog: ${isPVLog}, isAsync: ${isAsync}`);
    if (isPVLog) {
      return this.submitForPV(params, isAsync);
    } else {
      return this.submitForEvent(params, isAsync);
    }
  }

  /**
   * decides whether to use private ELB or Akamai'
   * @returns boolean
   */
  private shouldUsePrivateELB(): boolean {
    // must not allow file:// or coupang:// protocol to use private ELB
    let pageProtocol = document.location.protocol.toLowerCase();
    if (pageProtocol === 'http' || pageProtocol === 'https') {
      const pageHost = document.location.hostname || document.location.host.split(':')[0];
      for (let privateDomain of PRIVATE_DOMAINS) {
        if (pageHost.toLowerCase().endsWith(privateDomain)) {
          ConsoleLog.d(TAG, `#shouldUsePrivateELB - detected private ELB pageHost: ${pageHost}, privateDomain: ${privateDomain}`);
          return true;
        }
      }
    }
    return false;
  }

  private checkCustomHost(host, usePrivateELB) {
    if (host !== undefined && usePrivateELB) {
      ConsoleLog.d(TAG, `#init - private elb won't be used when custom host=${host} is set`);
    }
  }

  /**
   * Get platform, if platform is 'ios' or 'android' and browser has no Coupang App Logging interface,
   *   platform should be overriden to 'mweb'
   * @param {string} inputPlatform
   * @returns {string}
   */
  private detectPlatform(inputPlatform: string): string {
    let input = inputPlatform.trim().toLowerCase();
    let detected = input;
    // TODO : introduce new (enum) class to detect platform correctly.
    if (input === 'android' || input === 'ios') {
      if (!this.appLogRequester.isApp()) {
        ConsoleLog.d(TAG, `#detectPlatform - detected platform : from ${inputPlatform} => mweb`);
        detected = 'mweb';
      }
    }
    return detected;
  }

  /**
   * Send PageView data for logging.
   * At this time, event time and pvid are both newly created.
   *
   * @param params {RequestParams} JSON object for request
   * @param isAsync {boolean}
   */
  private async submitForPV(params: RequestParams, isAsync: boolean) {
    // Re-generate commonParameter when PV(PageView) log send. (for re-generate pvid)
    let isApp = this.appLogRequester.isApp();
    let requestJSON;
    this.commonParameter = this.generateCommonParameter();
    this.commonParameter.setEventTime(new Date().toISOString());

    requestJSON = this.generateRequestJSON(params);

    ConsoleLog.d(TAG, `#submitForPV - isApp: ${isApp}`);
    if (isApp) {
      this.appLogRequester.send(requestJSON);
      return Promise.resolve();
    }
    if (isAsync) {
      return await this.webLogRequester.send(requestJSON);
    }
    this.webLogRequester.sendSync(requestJSON);
    return Promise.resolve();
  }

  /**
   * Send Event data for logging.
   * At this time, event time is newly created, and pvid uses the previous value.
   *
   * @param params {RequestParams} JSON object for request
   * @param isAsync {boolean}
   */
  private async submitForEvent(params: RequestParams, isAsync: boolean) {
    // If you do not use PV Log, use commonParameter. (for reuse pvid)
    let isApp = this.appLogRequester.isApp();
    let requestJSON;
    if (!this.commonParameter) {
      ConsoleLog.w(TAG, `#submitForEvent - this.commonParameter is not initialized (generate common parameter)`);
      this.commonParameter = this.generateCommonParameter();
    }
    this.commonParameter.setEventTime(new Date().toISOString())
    // set again <memberSrl> to make sure the memberSrl is latest
    this.commonParameter.setMemberSrl();

    requestJSON = this.generateRequestJSON(params);

    ConsoleLog.d(TAG, `#submitForEvent - isApp: ${isApp}`);
    if (isApp) {
      this.appLogRequester.send(requestJSON);
      return Promise.resolve();
    }
    if (isAsync) {
      return this.webLogRequester.send(requestJSON);
    }
    this.webLogRequester.sendSync(requestJSON);
    return Promise.resolve();
  }

  /**
   * Generate a JSON object to send the common parameter.
   *
   * @returns {CommonParameter} Returns generated CommonParameter
   */
  private generateCommonParameter(): CommonParameter {
    return new CommonParameter().setPlatform(this.platform);
  }

  /**
   * Generate a JSON object to send the meta log data.
   *
   * @param meta {object} JSON object for data
   * @returns {MetaParameter} Returns generated MetaParameter
   */
  private generateMetaParameter(meta: MetaParams): MetaParameter {
    const {
      schemaId,
      schemaVersion
    } = meta;

    if (!schemaId || !schemaVersion) {
      ConsoleLog.w(TAG, `#generateMetaParameter - schemaId: ${schemaId}, schemaVersion: ${schemaVersion}`);
    }
    return new MetaParameter().setSchemaId(schemaId).setSchemaVersion(schemaVersion);
  }

  /**
   * Generate a JSON object to send the log data.
   *
   * @param data {object} JSON object for data
   * @returns {DataParameter} Returns generated DataParameter
   */
  private generateDataParameter(data: object): DataParameter {
    return new DataParameter().setData(data);
  }

  /**
   * Generate a JSON object to send the extra log data.
   *
   * @param extra {object} JSON object for extra
   * @returns {ExtraParameter} Returns generated ExtraParameter
   */
  private generateExtraParameter(extra: object): ExtraParameter {
    return new ExtraParameter().setExtraData(extra).setSentTime(new Date().toISOString());
  }

  /**
   * Generate a JSON object for the request.
   *
   * @param params {RequestParams} JSON object for request
   * @returns {{}} JSON object of CommonParameter + MetaParameter + DataParameter + ExtraParameter + CampaignParameter.
   */
  private generateRequestJSON(params: RequestParams): object {

    const { meta, data, extra } = params;

    let metaJSON = meta ? this.generateMetaParameter(meta).getJSON() : { meta : {} };
    let dataJSON = data ? this.generateDataParameter(data).getJSON() : { data : {} };
    let extraJSON = extra ? this.generateExtraParameter(extra).getJSON() : { extra : {} };

    let commonJSON = this.commonParameter.getJSON();

    if (this.appLogRequester.isApp()) {
      extraJSON['$webview'] = this.commonParameter.getExtraWebviewJSON(true);
    }

    return {
      ...commonJSON,
      ...metaJSON,
      ...dataJSON,
      ...extraJSON,
      ...this.campaignParameter,
    }
  }

  /**
   * According to the element's coupang web log custom attribute (data-coupang-web-log-event, data-coupang-web-log-params)
   * Bind event to document to automatically send log data.
   *
   * @param eventTypes {Array<string>} types of event
   */
  private bindEvent(eventTypes: Array<string>) {
    const eventHandler = (event) => {
      const type = event.type;
      let target = event.target;
      let foundElement = false;
      let eventType;

      while (!foundElement && target) {
        eventType = this.getEvent(target);
        if (eventType && eventType === type) {
          foundElement = true;
          break;
        }
        target = target.parentNode;
      }

      if (foundElement === true) {
        ConsoleLog.d(TAG, `#bindEvent - matched ${type} eventType`);
        let eventData = this.getRequestParam(target);
        this.submit(this.parameterStore.replaceDynamicString(eventData));
      }
    };

    WindowEventListener.add('load',() => {
      let i;
      let evnType;

      for (i = 0; i < eventTypes.length; i ++) {
        evnType = eventTypes[i];
        ConsoleLog.d(TAG, `#bindEvent - addEventListener eventType: ${evnType}`);
        document.addEventListener(evnType, eventHandler, false);
      }
    });
  }
}
