import throttle from 'lodash.throttle';
import AppLogRequester from '../../requesters/AppLogRequester';
import WebLogRequester from '../../requesters/WebLogRequester';
import CommonParameter from '../../parameters/CommonParameter';
import PerformanceLog from '../PerformanceLog/PerformanceLog';
import { addInstrumentationHandler } from '../../utils/instrument';

export default class PageViewLog extends PerformanceLog {
  schemaID: number;
  schemaVersion: number;

  constructor(webLogRequester: WebLogRequester, appLogRequester: AppLogRequester) {
    super(webLogRequester, appLogRequester);
    this.schemaID = 10943;
    this.schemaVersion = 4;
    this.reportPageView = throttle(this.reportPageView.bind(this), 1000);
  }

  /**
   * TTI Initializer
   *
   * @param initFields {
   *     pageName {string} - page name
   *     platform {string} - android|ios|web|mweb
   *     platformType {string} - native|browser|...
   *     screenType {string} - device screen type
   *     extra {object} - extra fields need added into the log
   *     async {boolean} - true async mode, false sync mode
   *     calcType {string} - tti calcuation type, 'default' means tti value will include redirect time, 'excludeRedirect' means tti value will exclude redirect time.
   * }
   */
  init(initFields: PerfInitFields): void {
    const {
      pageName,
      platform,
      platformType,
      screenType,
      extra,
      async = false,
      applicationId = 'no_applicationId_assigned',
    } = initFields;

    this.commonFields = this.makeCommonFields({
      eventName: 'web_page_view',
      domain: 'page view',
      pageName,
      platformType,
      applicationId,
    });

    this.baseOption = {
      screenType,
      extra,
      async,
    };

    this.commonParameter = new CommonParameter().setPlatform(platform);
    this.startTrackingPageView();
  }

  private startTrackingPageView() {
    // submit initial pageView
    this.reportPageView(this.toUrlObj(location.href));

    // add pageView listener for SPA applications
    addInstrumentationHandler('history', ({ to, from = '' }) => {
      const targetUrl = this.toUrlObj(to);
      if (!targetUrl) {
        return;
      }
      // By default, all history changes should report a pageview. However, some domains have need to compare route by themselves and decide if a pageview should be recorded
      if (!this.isSameRoute(from, to)) {
        this.reportPageView(targetUrl, from);
      }
    })
  }

  private reportPageView(targetUrl: URL, referrer: string = '') {
    const optionFields = {
      path: targetUrl.pathname,
      searchParams: targetUrl.searchParams.toString(),
      origin: targetUrl.origin,
      referrer,
      title: (document && document.title) || '',
      hash: targetUrl.hash,
      viewCount: 1,
      userAgent: typeof window !== 'undefined' && window.navigator && window.navigator.userAgent
    }

    this.submit('url', targetUrl.toString(), optionFields);
  }

  private isSameRoute(from: string, to: string) {
    // this function should always return false unless otherwise configured by business domains
    // @ts-ignore
    const isSameRoute = window.checkIsSameRouteForPageView || function checkIsSameRouteForPageView(_from: string, _to: string) {
      return false;
    }

    try {
      return isSameRoute(from, to);
    } catch(e) {
      return false;
    }
  }

  private toUrlObj(url?: string): URL | null {
    if (url == null) {
      return null;
    }

    try {
      return new URL(url, location.origin);
    } catch(e) {
      return null;
    }
  }
}
