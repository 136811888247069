export const supportMutationObserver = () => 'MutationObserver' in window;

export const calculateAreaPrecent = (target: Element): number => {
  const {
    left,
    right,
    top,
    bottom,
    width,
    height,
  } = target.getBoundingClientRect()

  if (!width || !height) return 0

  const viewWidth = right > window.innerWidth ? window.innerWidth - left :
    left >= 0 && right >= 0
      ? width
      : left < 0 && right >= 0
      ? right : 0
  const viewHeight = bottom > window.innerHeight ? window.innerHeight - top : 
    top >= 0 && bottom >= 0
      ? height
      : top < 0 && bottom >= 0
      ? bottom
      : 0

  return Math.max((viewWidth * viewHeight) / (width * height), 0)
}
