import Cookie from "../utils/Cookie";
import Constants from "./ConstantParameter";

export default {
  getCampaignParameter(): object {
    const campaign = {};

    Constants.COOKIE.CAMPAIGN.map(key => {
      const objKey = key.replace(/^trac\_/, '');
      const value = Cookie.getCookie(key).replace(/"/g, '');
      campaign[objKey] = (value !== "0") && value || '';
    });

    return campaign['src'] && campaign['spec'] && {
      campaign
    } || {};
  }
}