import CompatibilityChecker from './utils/CompatibilityChecker';

// reference) https://developers.google.com/chrome-developer-tools/docs/console?hl=ko
const makeArgumentsWithColor = (type: string, tag: string, msg: string, color: string): { message: string; color: string } => {
  let message = '';
  // tag
  message += `%c[${type}/${tag}]`;
  // message
  message += ` :  ${msg}`;
  color = `color: ${color};`;
  return {
    message,
    color
  };
};

const makeArgumentsWithoutColor = (type: string, tag: string, msg: string, color: string): { message: string; color: any } => {
  let message = '';
  // tag
  message += `[${type}/${tag}]`;
  // message
  message += ` : ${msg}`;
  return {
    message,
    color: null
  };
};

const makeArguments = CompatibilityChecker.isLowerThanIE10() ? makeArgumentsWithoutColor : makeArgumentsWithColor;

export default {
  isDebugEnabled: false,
  setDebugMode: function() {
    this.isDebugEnabled = true;
  },

  // debug
  d: function (tag: string, message: string) {
    if (window.console && this.isDebugEnabled) {
      let args = makeArguments('Debug', tag, message, 'purple');
      if (args.color) {
        console.log(args.message, args.color);
      } else {
        console.log(args.message);
      }
    }
  },

  // info
  i: function (tag: string, message: string) {
    if (window.console && this.isDebugEnabled) {
      let args = makeArguments('Info', tag, message, 'green');
      if (args.color) {
        console.info(args.message, args.color);
      } else {
        console.info(args.message);
      }
    }
  },

  // warn
  w: function (tag: string, message: string) {
    if (window.console && this.isDebugEnabled) {
      let args = makeArguments('Warn', tag, message, 'orange');
      if (args.color) {
        console.warn(args.message, args.color);
      } else {
        console.warn(args.message);
      }
    }
  },

  // error
  e: function (tag: string, message: string) {
    if (window.console && this.isDebugEnabled) {
      let args = makeArguments('Error', tag, message, 'red');
      if (args.color) {
        console.error(args.message, args.color);
      } else {
        console.error(args.message);
      }
    }
  }
};
