/**
 * This class provides Data Parameter.
 */
export default class DataParameter {
  private data: object;

  constructor() {
    this.data = {};
  }

  /**
   * Set the data.
   *
   * @param data {object} JSON object of data
   * @returns {DataParameter} Returns instance of DataParameter
   */
  setData(data: object) {
    this.data = data;
    return this;
  }

  /**
   * Returns JSON object of Data
   *
   * @returns {{data: Object}}
   */
  getJSON(): object {
    return {
      data: this.data
    };
  }
}