import LogInterceptor from './LogInterceptor';
import CompatibilityChecker from './utils/CompatibilityChecker';
import ConsoleLog from './utils/ConsoleLog';

const TAG = 'WebLogRequester';

const ERROR_TYPES = {
  INVALID_PAYLOAD: 'INVALID_PAYLOAD',
  INTERNAL_ERROR: 'INTERNAL_ERROR',
};
const XDRRequests = [];
const isSupportXMLRequest = CompatibilityChecker.isSupportXMLRequest();

/**
 * This class provides the function to transfer log data to Log Gateway.
 */
export default class WebLogRequester {
  private readonly singleSubmitEndpoint: string;
  private isSupportXMLRequest: boolean = isSupportXMLRequest;
  private logInterceptor: LogInterceptor<object>;

  constructor({ singleSubmitEndpoint }: EndpointInfo, logInterceptor?: LogInterceptor<object>) {
    this.logInterceptor = logInterceptor || new LogInterceptor<object>();
    this.singleSubmitEndpoint = singleSubmitEndpoint;
  }

  /**
   * This function provides the function to transfer log data to Log Gateway.
   *
   * @param data
   * @returns {Promise}
   */
  async send(data: object): Promise<AsyncXMLHttpResolve | AsyncXDomainResolve> {
    ConsoleLog.d(TAG, `#send - is support 'XMLHttpRequest':${isSupportXMLRequest}`);
    const params = this.logInterceptor.runRequestInterceptor(data);
    
    try {
      if (this.isSupportXMLRequest === true) {
        return await this.sendXMLHttpRequest(params);
      } else {
        return await this.sendXDomainRequest(params);
      }
    } catch (err) {
      ConsoleLog.e(TAG, err.message);
      throw err;
    }
  }

  /**
   * This function provides the function to transfer log data to Log Gateway.
   *
   * @param data
   * @returns {Promise}
   */
  sendSync(data: object): void {
    const img = new Image();
    const query = `record=${encodeURIComponent(JSON.stringify(data))}`;
    img.src = `${this.singleSubmitEndpoint}?${query}`;
    ConsoleLog.i(TAG, `#sendLoggingRequest - sendSync : success`);
  }

  /**
   * It is judged whether it is a valid response.
   *
   * @param request {XMLHttpRequest}
   * @returns {boolean}
   */
  private isValidResponse(request: XMLHttpRequest | XDomainRequest) {
    const responseData = request.responseText ? JSON.parse(request.responseText) : {};
    const type = responseData.type;
    return !(type && (type === ERROR_TYPES.INTERNAL_ERROR || type === ERROR_TYPES.INVALID_PAYLOAD));
  }

  /**
   * Request using XMLHttpRequest. (method is POST)
   *
   * @param data
   * @returns {XMLHttpRequest}
   */
  private sendXMLHttpRequest(data: object): Promise<AsyncXMLHttpResolve> {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      const jsonText = this.convertJsonToString(data);
      let isSuccess = false;

      request.open('POST', this.singleSubmitEndpoint, true);
      request.setRequestHeader('Content-Type', 'text/plain');
      request.onload = () => {
        const {
          readyState,
          status,
          statusText,
          responseText
        } = request;

        isSuccess = readyState === XMLHttpRequest.DONE && request.status === 200 && this.isValidResponse(request);

        if (isSuccess) {
          ConsoleLog.i(TAG, `#sendXMLHttpRequest - onload\n status: ${status}, statusText: ${statusText}, responseText: ${responseText}`);
          resolve({
            status,
            statusText,
            responseText
          });
        } else {
          const errorMsg = `#sendXMLHttpRequest - onload\n status: ${status}, statusText: ${statusText}, responseText: ${responseText}`;
          reject(new Error(errorMsg));
        }
      };
      request.onerror = () => {
        const {
          status,
          statusText,
          responseText
        } = request;
        const errorMsg = `#sendXMLHttpRequest - onerror\n status:${status}, statusText: ${statusText}, responseText: ${responseText}`;
        reject(new Error(errorMsg));
      };
      request.send(jsonText);
      ConsoleLog.d(TAG, `#sendXMLHttpRequest - send data \n${jsonText}`);
    });
  }

  /**
   * Request using IE8 provided XDomainRequest. (method is POST)
   * https://stackoverflow.com/questions/8058446/ie-xdomainrequest-not-always-work
   * @param data
   * @returns {XDomainRequest}
   */
  private sendXDomainRequest(data: object): Promise<AsyncXDomainResolve> {
    return new Promise((resolve, reject) => {
      const request = new XDomainRequest();
      const requestTimeout = Date.now() + 50;
      const jsonText = this.convertJsonToString(data);
      const setXDRCompleted = () => {
        const idx = XDRRequests.indexOf(request);
        (idx >= 0) && XDRRequests.splice(idx, 1);
        request.onload = null;
        request.onerror = null;
        request.ontimeout = null;
        request.onprogress = null;
      };

      request.onload = () => {
        const responseText = request.responseText;
        let isSuccess = this.isValidResponse(request);

        if (isSuccess) {
          ConsoleLog.i(TAG, `#sendXDomainRequest - onload\n responseText: ${responseText}`);
          resolve({
            responseText
          });
        } else {
          reject(new Error(`#sendXDomainRequest - onload\n responseText: ${responseText}`));
        }
        setXDRCompleted();
      };
      request.onerror = () => {
        reject(new Error(`#sendXDomainRequest - onerror\n responseText: ${request.responseText}`));
        setXDRCompleted();
      };
      /* tslint:disable:no-empty */
      request.ontimeout = () => {
        setXDRCompleted();
      };
      request.onprogress = () => {};
      /* tslint:enable:no-empty */

      request.open('POST', this.singleSubmitEndpoint);
      request.send(jsonText);
      XDRRequests.push(request);
      // to prevent request abortion
      while (Date.now() < requestTimeout) { continue; }
      ConsoleLog.d(TAG, `#sendXDomainRequest - send data \n${jsonText}`);
    });
  }

  /**
   * Convert JSON to string.
   *
   * @param data
   * @returns {string}
   */
  private convertJsonToString(data: object): string {
    return JSON.stringify(data || {});
  }
}
