/**
 * Number related utility methods
 */

/**
 * Make sure the num is natural number
 *
 * @returns 0 if num < 0 or is not avialbe number, otherwise return the num itself.
 */
export function naturalize(num: number): number {
  return (!num || num < 0) ? 0 : num;
}
