import ConsoleLog from './utils/ConsoleLog';

const TAG = 'ParameterStore';

export default class ParameterStore {
  static DYNAMIC_VALUE_FLAG = '@';
  static REGEXP_DYNAMIC_VALUE = /("@(\w+)(|[.]\w+)+")/g;
  static FIELD_SEPARATOR = '.';

  private store: object;
  constructor() {
    this.store = {};
  }

  register(key: string, data: any) {
    if (!key) {
      ConsoleLog.w(TAG, `#register - key argument is not exist.`);
      return;
    }
    if (typeof data === 'undefined') {
      ConsoleLog.w(TAG, `#register - 'Data is not exist. Can not register data in ${key}.`);
      return;
    }

    this.store[key] = data;
    ConsoleLog.d(TAG, `#register - data \n${JSON.stringify(data)}`);
  }

  getRegisteredData(key: string): object {
    if (!key) {
      ConsoleLog.w(TAG, `#getRegisteredData - key argument is not exist.`);
      return null;
    }

    if (typeof this.store[key] === 'undefined') {
      ConsoleLog.w(TAG, `#getRegisteredData - has not registered data by "${key}" key.`);
      return null;
    }

    let registeredData = this.store[key];
    ConsoleLog.d(TAG, `#getRegisteredData - registered data \n${JSON.stringify(registeredData)}`);
    return registeredData;
  }

  findValueFromPath(path) {
    let i;
    let foundValue;
    let keys;

    if (!path) {
      ConsoleLog.w(TAG, `#findValueFromPath - path is empty`);
      return;
    }

    keys = path.replace(/"/g, '').replace(ParameterStore.DYNAMIC_VALUE_FLAG, '').split(ParameterStore.FIELD_SEPARATOR);

    foundValue = this.store[keys[0]];
    for (i = 1; i < keys.length; i++) {
      foundValue = foundValue[keys[i]];
      if (typeof foundValue === 'undefined') {
        foundValue = '';
        ConsoleLog.w(TAG, `#findValueFromPath - found undefined value ${path} > replaced with an empty string.`);
      }
      ConsoleLog.i(TAG, `#findValueFromPath - found value ${path}/${foundValue}`);
    }

    if (typeof foundValue === 'string') {
      return `"${foundValue}"`;
    }

    if (typeof foundValue === 'object') {
      return JSON.stringify(foundValue);
    }
    return foundValue;
  }

  replaceDynamicString(params: string | object): RequestParams {
    let jsonString = JSON.stringify(params);

    jsonString = jsonString.replace(
      ParameterStore.REGEXP_DYNAMIC_VALUE,
      matchedString => {
        let value = this.findValueFromPath(matchedString);
        ConsoleLog.d(TAG, `#replaceDynamicString - replace string ${matchedString} > ${value}`);
        return value;
      }
    );
    return JSON.parse(jsonString);
  }

  merge(...arg): object {
    // letiables
    let extended = {};
    let deep = false;
    let i = 0;
    let length = arg.length;

    // Check if a deep merge
    if (typeof arg[0] === 'boolean') {
      deep = arg[0];
      i++;
    }

    // Merge the object into the extended object
    let merge = obj => {
      for (let prop in obj) {
        // If deep merge and property is an object, merge properties
        if (deep && typeof obj[prop] === 'object') {
          extended[prop] = this.merge(true, extended[prop], obj[prop]);
        } else {
          extended[prop] = obj[prop];
        }
      }
    };

    // Loop through each object and conduct a merge
    for (; i < length; i++) {
      let obj = arguments[i];
      merge(obj);
    }
    return extended;
  }
}
