import ConsoleLog from './utils/ConsoleLog';

type RequestInterceptorType<T> = (params: T) => T;

class LogInterceptor<T> {
  private requestInterceptor: RequestInterceptorType<T>[] = [];
  public useRequestInterceptors(callback: RequestInterceptorType<T>[]) {
    this.useRequestInterceptor(callback);
  }
  public useRequestInterceptor(callback: RequestInterceptorType<T> | RequestInterceptorType<T>[]) {
    try {
      const callbackList = (() => {
        if (Array.isArray(callback)) return callback;
        return [callback];
      })();
      callbackList.forEach((callbackItem) => {
        if (typeof callbackItem !== 'function') return;
        this.requestInterceptor.push(callbackItem);
      });
    } catch (e) {
      ConsoleLog.d(LogInterceptor.name, `#useRequestInterceptor error - ${e.message}`);
    }
  }

  public runRequestInterceptor(_params: T) {
    this.requestInterceptor.forEach((callback) => {
      try {
        const _modified = callback(_params);
        if (_modified) {
          _params = _modified;
        }
      } catch (e) {
        ConsoleLog.d(LogInterceptor.name, '#submit - Unexpected request interceptor');
      }
    });
    return _params;
  }
}

export default LogInterceptor;
