/* tslint:disable */
import ES6Promise from 'es6-promise';
import CompatibilityChecker from './utils/CompatibilityChecker';

if (CompatibilityChecker.isSupported()) {
  ES6Promise.polyfill();

  // https://developer.mozilla.org/docs/Web/JavaScript/Reference/Global_Objects/Date/toISOString
  if (!Date.prototype.toISOString) {
    (function() {
      function pad(number: number) {
        if (number < 10) {
          return '0' + number;
        }
        return number;
      }

      Date.prototype.toISOString = function() {
        return this.getUTCFullYear() +
          '-' + pad(this.getUTCMonth() + 1) +
          '-' + pad(this.getUTCDate()) +
          'T' + pad(this.getUTCHours()) +
          ':' + pad(this.getUTCMinutes()) +
          ':' + pad(this.getUTCSeconds()) +
          '.' + (this.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) +
          'Z';
      };
    }());
  }

  // https://gist.github.com/jonathantneal/3748027
  !window.addEventListener && (function (WindowPrototype: Window, DocumentPrototype: Document, ElementPrototype: Element, addEventListener: string, removeEventListener: string, dispatchEvent: string, registry) {
    WindowPrototype[addEventListener] = DocumentPrototype[addEventListener] = ElementPrototype[addEventListener] = function (type, listener) {
      let target = this;

      registry.unshift([target, type, listener, function (event: IEEvent) {
        event.currentTarget = target;
        event.preventDefault = function () { event.returnValue = false };
        event.stopPropagation = function () { event.cancelBubble = true };
        event.target = event.srcElement || target;

        listener.call(target, event);
      }]);

      this.attachEvent("on" + type, registry[0][3]);
    };

    WindowPrototype[removeEventListener] = DocumentPrototype[removeEventListener] = ElementPrototype[removeEventListener] = function (type, listener) {
      for (let index = 0, register; register = registry[index]; ++index) {
        if (register[0] == this && register[1] == type && register[2] == listener) {
          return this.detachEvent("on" + type, registry.splice(index, 1)[0][3]);
        }
      }
    };

    WindowPrototype[dispatchEvent] = DocumentPrototype[dispatchEvent] = ElementPrototype[dispatchEvent] = function (eventObject) {
      return this.fireEvent("on" + eventObject.type, eventObject);
    };
  })(Window.prototype, HTMLDocument.prototype, Element.prototype, "addEventListener", "removeEventListener", "dispatchEvent", []);

  if (typeof Object.assign !== 'function') {
    (function () {
      Object.assign = function (target) {
        if (target === undefined || target === null) {
          throw new TypeError('Cannot convert undefined or null to object');
        }

        let output = Object(target);
        for (let index = 1; index < arguments.length; index++) {
          let source = arguments[index];
          if (source !== undefined && source !== null) {
            for (let nextKey in source) {
              if (source.hasOwnProperty(nextKey)) {
                output[nextKey] = source[nextKey];
              }
            }
          }
        }
        return output;
      };
    })();
  }

  if (window.performance && !window.performance.now) {
    Date.now = (Date.now || function () {
      return new Date().getTime();
    });

    window.performance.now = function now() {
      return Date.now() - (performance.timing && performance.timing.navigationStart || Date.now());
    };
  }
}