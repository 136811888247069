import EndPointsConfig from './EndPointsConfig';

export default class ConfigResolver {
  private readonly endpointsConfig: EndPointsConfig;

  constructor(config: Config) {
    this.endpointsConfig = new EndPointsConfig({ ...config });
  }

  resolveEndpoints(): EndpointInfo {
    return {
      singleSubmitEndpoint: this.endpointsConfig.getSingleSubmitEndpoint(),
    };
  }
}
