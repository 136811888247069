/**
 * Provides the Extra Parameter.
 */
export default class ExtraParameter {
  private sentTime: string;
  private extraData: object;

  /**
   * Set the data.
   *
   * @param extraData {object} extra data
   * @returns {ExtraParameter}
   */
  setExtraData(extraData: object) {
    this.extraData = extraData;
    return this;
  }

  /**
   * Set the time of log was sent.
   *
   * @param sentTime {string} log를 전송한 시간
   * @returns {ExtraParameter} Returns instance of ExtraParameter
   */
  setSentTime(sentTime: string) {
    this.sentTime = sentTime;
    return this;
  }

  /**
   * Returns JSON object of ExtraParameter
   * @returns {{extra: {sentTime: string}}}
   */
  getJSON():object {
    const {
      sentTime,
      extraData
    } = this;

    return {
      extra: {
        sentTime,
        ...extraData
      }
    };
  }
}