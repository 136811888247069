export default class TTI {
  private startTime: number;
  private endTime: number;
  private optionFields: OptionFields;

  constructor(startTime: number, optionFields: OptionFields) {
    this.startTime = startTime;
    this.optionFields = optionFields;
  }

  stop(endTime: number): void {
    this.endTime = endTime;
  }

  measureLatency(): number {
    return Math.floor(this.endTime - this.startTime);
  }

  getOptionFields(): OptionFields {
    return this.optionFields;
  }
}
