import WebLog from './WebLog';
import CompatibilityChecker from './utils/CompatibilityChecker';

// 지원하지 않는 브라우저에서 동작할 경우 WebLog 의 function 을 모두 empty function 으로 설정한다.
if (!CompatibilityChecker.isSupported()) {
  let propName: string;

  /* tslint:disable:no-empty */
  const emptyFunction = function(){};
  /* tslint:enable:no-empty */

  for (propName in WebLog.prototype) {
    if (typeof WebLog.prototype[propName] === 'function') {
      WebLog.prototype[propName] = emptyFunction;
    }
  }
}

export default new WebLog();
