import CommonWebParameter from './CommonWebParameter';
import Cookie from './utils/Cookie';

const LIBRARY_VERSION = '1.4.3';

/**
 * This class provides data that is commonly used for all log data.
 */
export default class CommonParameter {
  private platform: string;
  private pcid: string;
  private memberSrl?: string;
  private libraryVersion?: string;
  private lang: string;
  private resolution?: string;
  private eventTime: string;

  private commonWebParameter?: CommonWebParameter;

  constructor() {
    this.setLang()
        .setMemberSrl()
        .setPCID()
        .setResolution()
        .setLibraryVersion(LIBRARY_VERSION)
        .setCommonWebParameter(new CommonWebParameter());
  }

  /**
   * Set the platform value.
   *
   * @param platform {string} app인 경우 자동으로 값이 설정한 된다.
   *
   * @returns {CommonParameter} instance of CommonParameter
   */
  setPlatform(platform: string) {
    this.platform = platform;
    return this;
  }

  /**
   * Set the eventTime value.
   *
   * @param eventTime
   * @returns {CommonParameter} instance of CommonParameter
   */
  setEventTime(eventTime: string) {
    this.eventTime = eventTime;
    return this;
  }

  /**
   * Set the PCID of the client based on the cookie value (PCID).
   *
   * @returns {CommonParameter} instance of CommonParameter
   */
  setPCID() {
    this.pcid = Cookie.getCookie('PCID') || this.generatePCID();
    return this;
  }

  /**
   * Set memberSrl based on cookie value (member_srl).
   *
   * @returns {CommonParameter} instance of CommonParameter
   */
  setMemberSrl() {
    this.memberSrl = Cookie.getCookie('member_srl');
    return this;
  }

  /**
   * Set the version of CoupangWebLog.
   *
   * @param libraryVersion CoupangWebLog library version.
   * @returns {CommonParameter} instance of CommonParameter
   */
  setLibraryVersion(libraryVersion: string) {
    this.libraryVersion = libraryVersion;
    return this;
  }

  /**
   * Sets the browser's locale information.
   *
   * @returns {CommonParameter} instance of CommonParameter
   */
  setLang() {
    this.lang = navigator.languages && navigator.languages[0] ||
                navigator.language ||
                navigator.userLanguage;
    return this;
  }

  /**
   * Set resolution information of device.
   *
   * @returns {CommonParameter} instance of CommonParameter
   */
  setResolution() {
    this.resolution = `${window.screen.width}x${window.screen.height}`;
    return this;
  }

  /**
   * JSON object of CommonParameter
   *
   * @returns {{platform: string, clientId: string, memberSrl: string, libraryVersion: string, locale: string, resolution: string, eventTime: string}}
   */
  getJSON(): { common: any } {
    const {
      platform,
      pcid,
      memberSrl,
      libraryVersion,
      lang,
      resolution,
      eventTime,

      commonWebParameter
    } = this;

    let commonWebJSON = commonWebParameter.getJSON();

    return {
      common: {
        platform,
        pcid,
        memberSrl,
        libraryVersion,
        lang,
        resolution,
        eventTime,

        ...commonWebJSON
      }
    };
  }

  /**
   * Return library version
   * @returns {string}
   */
  getLibVersion(): string {
    return LIBRARY_VERSION;
  }

  /**
   * Return some common properties which should be set to extra, to be preserved in app.
   * the CommonWebParameter.
   *
   * @param shorten if true, too long url and referrer will be cut off
   * @returns {CommonParameter}
   */
  getExtraWebviewJSON(shorten?: boolean): object {
    const commonWebJSON = this.commonWebParameter.getJSON();
    const extraWebviewJSON = {
      eventTime : this.eventTime,
      pcid : this.pcid,
      libraryVersion: this.getLibVersion(),
      ...commonWebJSON['web'],
    };

    if (shorten) {
      let url = extraWebviewJSON.url || '';
      let referrer = extraWebviewJSON.referrer || '';
      if (url.length > 100) {
        extraWebviewJSON.url = url.substring (0,100) + '......';
      }
      if (referrer.length > 100) {
        extraWebviewJSON.referrer = referrer.substring (0,100) + '......';
      }
    }
    return extraWebviewJSON;
  }

  /**
   * Generate PCID and set generated value to cookie
   * @returns {string}
   */
  private generatePCID(): string {
    const PCID_LENGTH = 23;
    const date = new Date();
    let generated = [];
    let pcid;

    generated.push(date.getTime());
    generated.push(Math.random().toString().substr(2,));

    Cookie.setCookie("PCID", pcid = generated.join("").substr(0, PCID_LENGTH));

    return pcid;
  }

  /**
   * Set the CommonWebParameter.
   *
   * @param commonWebParameter
   * @returns {CommonParameter}
   */
  private setCommonWebParameter(commonWebParameter: CommonWebParameter) {
    this.commonWebParameter = commonWebParameter;
    return this;
  }
}
