import ConsoleLog from './utils/ConsoleLog';
import ParameterStore from './parameters/ParameterStore';

const TAG = 'AttrDataUtil';
const WEB_LOG_BASE_ATTRIBUTE_NAME = 'data-web-log';
const WEB_LOG_EVENT_ATTRIBUTE = `${WEB_LOG_BASE_ATTRIBUTE_NAME}-event`;
const WEB_LOG_PARAM_ATTRIBUTE = `${WEB_LOG_BASE_ATTRIBUTE_NAME}-param`;

/**
 * Utility for attribute data of element.
 */
export default class AttrDataUtil {
  /**
   * Set the event(data-web-log-event) value in the element.
   *
   * @param element {Element} element of document
   * @param eventType {string} type of event
   */
  setEvent(element: Element, eventType: string) {
    if (eventType) {
      element.setAttribute(WEB_LOG_EVENT_ATTRIBUTE, eventType);
      ConsoleLog.d(TAG, `#setEvent - eventType: ${eventType}`);
    } else {
      ConsoleLog.w(TAG, `#setEvent - eventType is empty`);
    }
  }

  /**
   * Get the event(data-web-log-event) value defined in element.
   *
   * @param element {Element} element of document
   * @returns {string} type of event
   */
  getEvent(element: Element): string {
    const eventType = element && element.getAttribute && element.getAttribute(WEB_LOG_EVENT_ATTRIBUTE) || '';
    ConsoleLog.d(TAG, `#getEvent - eventType${eventType ? `: ${eventType}` : ` is empty`}`);
    return eventType;
  }

  /**
   * Set the parameter(data-web-log-param) value in the element.
   *
   * @param element {Element} element of document
   * @param jsonData {object}
   */
  setRequestParam(element: Element, jsonData: object | string) {
    if (jsonData) {
      if (typeof jsonData === 'string' && jsonData.indexOf(ParameterStore.DYNAMIC_VALUE_FLAG) === 0) {
        element.setAttribute(WEB_LOG_PARAM_ATTRIBUTE, jsonData);
        ConsoleLog.d(TAG, `#setRequestParam - jsonData: ${jsonData}`);
      } else {
        let jsonString = JSON.stringify(jsonData);
        element.setAttribute(WEB_LOG_PARAM_ATTRIBUTE, jsonString);
        ConsoleLog.d(TAG, `#setRequestParam - jsonData: ${jsonString}`);
      }
    } else {
      ConsoleLog.w(TAG, `#setRequestParam - jsonData is empty`);
    }
  }

  /**
   * Get the parameter(data-web-log-param) value defined in the Element.
   *
   * @param element {Element} element of document
   * @returns {RequestParams|string}
   */
  getRequestParam(element: Element): RequestParams | string {
    const jsonString = element && element.getAttribute && element.getAttribute(WEB_LOG_PARAM_ATTRIBUTE) || '{}';
    if (jsonString.indexOf(ParameterStore.DYNAMIC_VALUE_FLAG) === 0) {
      ConsoleLog.d(TAG, `#getRequestParam resister parameter string - \n${jsonString}`);
      return jsonString;
    } else {
      ConsoleLog.d(TAG, `#getRequestParam - \n${jsonString}`);
      return JSON.parse(jsonString);
    }
  }
}
