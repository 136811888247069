import ConsoleLog from '../../utils/ConsoleLog';
import AppLogRequester from '../../requesters/AppLogRequester';
import WebLogRequester from '../../requesters/WebLogRequester';
import CommonParameter from '../../parameters/CommonParameter';
import NetworkParameter from '../../parameters/NetworkParameter';
import ExtraParameter from '../../parameters/ExtraParameter';

const TAG = 'PerformanceLog';

export default class PerformanceLog {
  schemaID: number;
  schemaVersion: number;
  performanceObjects: object;
  commonFields: CommonFields;
  baseOption: OptionFields;
  serverTime: number;
  domReady: number;
  asyncLogs: AsyncLogs[];
  commonParameter: CommonParameter;
  webLogRequester: WebLogRequester;
  appLogRequester: AppLogRequester;
  calcType: string;

  constructor(webLogRequester: WebLogRequester, appLogRequester: AppLogRequester) {
    this.performanceObjects = {};
    this.asyncLogs = [];
    this.webLogRequester = webLogRequester;
    this.appLogRequester = appLogRequester;
  }

  submit(perfKey: string, perfData: number | string, optionFields: OptionFields): void {
    const isApp = this.appLogRequester.isApp();
    const requestJSON = this.makeParams(perfKey, perfData, optionFields);
    if (isApp) {
      this.appLogRequester.send(requestJSON)
    } else {
      this.webLogRequester.send(requestJSON);
    }
    ConsoleLog.d(TAG, `#submitSync - ${perfKey}: ${perfData}, serverTime: ${optionFields.serverTime}, domReady: ${optionFields.domReady}, imageLoadingTime: ${optionFields.imageLoadingTime}`);
  }

  makeCommonFields(extra) {
    return {
      logCategory: 'system',
      logType: 'performance',
      eventName: 'web_latency_track_log',
      ixid: this.generateUUID(),
      ...extra,
    }
  }

  private makeParams(perfKey: string, perfData: number | string, optionFields: OptionFields): PerfParams {
    const { pcid, platform } = this.commonParameter.getJSON().common || {};
    const extraData = {
      ...new NetworkParameter().getNetworkParameters(),
      url: location.href,
      pcid,
      platform,
      ...optionFields.extra,
      calcType: this.calcType
    };

    const extra = new ExtraParameter().setExtraData(extraData).setSentTime(new Date().toISOString()).getJSON();
    this.commonParameter.setEventTime(new Date().toISOString());
    delete optionFields.extra;

    return {
      ...this.commonParameter.getJSON(),
      ...extra,
      meta: {
        schemaId: this.schemaID,
        schemaVersion: this.schemaVersion,
      },
      data: Object.assign({ [perfKey]: perfData }, this.commonFields, optionFields)
    };
  }

  generateUUID(): string {
    let uuid = '';

    for(let i = 0; i < 32; i++) {
      const random = Math.random() * 16 | 0;

      if (i === 8 || i === 12 || i === 16 || i === 20) {
        uuid += '-';
      }
      uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(16);
    }

    return uuid;
  }
}
