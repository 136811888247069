/**
 * Provides the Meta Parameter.
 */
export default class MetaParameter {
  private schemaId: string | number;
  private schemaVersion: string | number;

  /**
   * Set the schema Id.
   *
   * @param schemaId {string | number} schema Id
   * @returns {MetaParameter} Returns instance of MetaParameter
   */
  setSchemaId(schemaId: string | number) {
    this.schemaId = schemaId;
    return this;
  }

  /**
   * Set the schema version
   *
   * @param schemaVersion {number} schema version
   * @returns {MetaParameter} Returns instance of MetaParameter
   */
  setSchemaVersion(schemaVersion: string | number) {
    this.schemaVersion = schemaVersion;
    return this;
  }

  /**
   * Returns JSON object of MetaParameter
   * @returns {{meta: {schemaId: string|number, schemaVersion: string|number}}}
   */
  getJSON():object {
    const {
      schemaId,
      schemaVersion
    } = this;

    return {
      meta: {
        schemaId,
        schemaVersion
      }
    };
  }
}