import AppLogRequester from '../../requesters/AppLogRequester';
import WebLogRequester from '../../requesters/WebLogRequester';
import PerformanceLog from '../PerformanceLog/PerformanceLog';
import { supportMutationObserver, calculateAreaPrecent } from '../../utils/PerformanceUtils';
import CommonParameter from '../../parameters/CommonParameter';

interface TagElement {
  element: Element
  weightScore: number
  childList: Array<TagElement>
  elementArr: Array<Els>
}

interface Els {
  weight: number
  weightScore: number
  element: Element
}

const tagWeightMap = new Map([
  ['VIDEO', 5],
  ['CANVAS', 5],
  ['IMG', 2],
  ['SVG', 2],
]);

const IGNORE_TAG_LIST = ['SCRIPT', 'STYLE', 'META', 'HEAD', 'LINK'];
const FMP_TAG = 'fmp-tag';

let timeoutId;

export default class FMPLog extends PerformanceLog {
  schemaID: number;
  schemaVersion: number;
  fmp: number;
  private observe?: MutationObserver
  private markCount: number = 0
  private resolveFn: ((val: number) => void) | null
  private rejectFn: (() => void) | null
  private statusObserve: Array<{ time: number }> = []
  private entries: { [key: string]: any } = {}

  constructor(webLogRequester: WebLogRequester, appLogRequester: AppLogRequester) {
    super(webLogRequester, appLogRequester);
    this.resolveFn = null
    this.rejectFn = null;
    this.schemaID = 10798;
    this.schemaVersion = 1;
    // allow custom fmp tag log
    if (this.checkMarkStatus(document.body)) {
      this.activeMark()
    } else {
      this.passiveMark()
    }
  }

  init(initFields: PerfInitFields): void {
    const {
      pageName,
      platform,
      platformType,
      screenType,
      extra,
      async = false,
      applicationId = 'no_applicationId_assigned',
    } = initFields;

    this.commonFields = this.makeCommonFields({
      domain: 'fmp',
      pageName,
      platformType,
      applicationId,
    });

    this.baseOption = {
      screenType,
      extra,
      async,
    };

    this.commonParameter = new CommonParameter().setPlatform(platform);
    this.onFMP();
  }

  onFMP() {
    timeoutId = setTimeout(() => {
      this.getFirstMeaningFulPaint().then(res => {
        this.fmp = Math.round(res) || -1;
        if (this.fmp > 0) {
          this.goSubmit();
        } else {
          clearTimeout(timeoutId);
          this.onFMP();
        }
      }).catch(() => {
        this.fmp = -1;
      })
    }, 200);
  }

  public getFirstMeaningFulPaint(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.resolveFn = resolve;
      this.rejectFn = reject;

      if (document.readyState === 'complete') {
        this.getCoreElement()
      } else {
        window.addEventListener('load', () => {
          this.getCoreElement()
        })
      }
    })
  }

  private checkMarkStatus(element: Element): boolean {
    if (!element) return false;
    if (element.getAttribute(FMP_TAG)) return true
    const children = element.children
    for (let i = 0; i < children.length; i++) {
      const child = children[i]
      if (this.checkMarkStatus(child)) return true
    }
    return false
  }

  private getCoreElement() {
    if (!supportMutationObserver()) {
      this.rejectFn();
      console.error("browser doesn't support mutationObserver api");
      return;
    }

    this.observe!.disconnect()

    performance.getEntries().forEach((entry: PerformanceEntryPolyfill) => {
      this.entries[entry.name] = entry.responseEnd
    })
    const tagEle = this.getTreeWeight(document.body)
    let maxWeightEle: TagElement | null = null

    tagEle.childList.forEach((child) => {
      if (maxWeightEle && maxWeightEle.weightScore) {
        if (child.weightScore > maxWeightEle.weightScore) {
          maxWeightEle = child
        }
      } else {
        maxWeightEle = child
      }
    })

    if (!maxWeightEle) {
      this.resolveFn(0)
      return
    }

    let els = this.filterEls((maxWeightEle as TagElement).elementArr)

    this.resolveFn!(this.getElementTiming(els))
  }

  private activeMark() { }

  private passiveMark() {
    this.getFirstSnapShot()
    this.observe = new MutationObserver(() => {
      let time = performance.now()
      this.markCount++
      this.statusObserve.push({ time })
      this.setTag(document.body, this.markCount)
    })

    this.observe.observe(document, {
      childList: true,
      subtree: true,
    })
  }

  private getFirstSnapShot() {
    let time = performance.now()
    this.setTag(document.body, this.markCount)
    this.statusObserve.push({ time })
  }

  private getTreeWeight(element: Element): TagElement | null {
    if (!element) return null
    const list: Array<TagElement> = [],
      children = element.children
    for (let i = 0; i < children.length; i++) {
      const child = children[i]
      if (!child.getAttribute(FMP_TAG)) continue

      const elementArr = this.getTreeWeight(child);
      if (elementArr?.weightScore) list.push(elementArr!)
    }
    return this.calculateScore(element, list)
  }

  private setTag(element: Element, count: number) {
    const tagName = element.tagName
    if (IGNORE_TAG_LIST.indexOf(tagName) > -1) return
    const children = element.children
    for (let i = 0; i < children.length; i++) {
      const child = children[i]
      if (!child.getAttribute(FMP_TAG)) {
        if (!calculateAreaPrecent(child)) continue
        child.setAttribute(FMP_TAG, `${count}`)
      }
      this.setTag(child, count)
    }
  }

  private getElementTiming(els: Array<Els>): number {
    let result = 0
    els.forEach((el) => {
      let time = 0
      if (el.weight === 1) {
        let index = parseInt(el.element.getAttribute(FMP_TAG)!, 10)
        time = this.statusObserve[index].time
      } else if (el.weight === 2) {
        if (el.element.tagName === 'IMG') {
          time = this.entries[(el.element as HTMLImageElement).src]
        } else if (el.element.tagName === 'SVG') {
          let index = parseInt(el.element.getAttribute(FMP_TAG)!, 10)
          time = this.statusObserve[index].time
        }
      } else if (el.weight === 5) {
        if (el.element.tagName === 'VIDEO') {
          time = this.entries[(el.element as HTMLVideoElement).src]
        } else if (el.element.tagName === 'CANVAS') {
          let index = parseInt(el.element.getAttribute(FMP_TAG)!, 10)
          time = this.statusObserve[index].time
        }
      }
      result = Math.max(result, time)
    })
    return result
  }

  private filterEls(els: Array<Els>) {
    if (els.length === 1) return els

    let sum = 0
    els.forEach((el) => {
      sum += el.weightScore
    })

    let avg = sum / els.length
    return els.filter((el) => el.weightScore > avg)
  }

  private calculateScore(
    element: Element,
    list: Array<TagElement>,
  ): TagElement {
    const { width, height } = element.getBoundingClientRect()

    let weight = tagWeightMap.get(element.tagName) || 1,
      childScore = 0

    list.forEach((el) => {
      childScore += el.weightScore
    })

    // compute total score
    let weightScore = calculateAreaPrecent(element)
      ? width * height * weight * calculateAreaPrecent(element)
      : 0

    let elementArr = [{ element, weight, weightScore }]

    if (weightScore < childScore || weightScore === 0) {
      weightScore = childScore
      elementArr = []
      list.forEach((el) => {
        elementArr = elementArr.concat(el.elementArr)
      })
    }

    element.setAttribute('fmp_weight', `${weightScore}`)
    return {
      weightScore,
      elementArr,
      childList: list,
      element,
    }
  }

  private goSubmit(): void {
    super.submit('fmp', this.fmp, {});
  }
}
