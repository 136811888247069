const IEVersionCheckRegExp = /MSIE ([0-9]{1,}[\\.0-9]{0,})/;
const IETridentCheckRegExp = /Trident\/(\d)/i;

const IEChecker = {
  /**
   * Returns whether IE Browser. (If you need IE11 browser check, please use "isIE11" function.)
   *
   * @returns {boolean} true or false.
   */
  isIE() {
    return navigator.userAgent.toLowerCase().indexOf('msie') > -1;
  },

  /**
   * Returns whether IE11 Browser. (If you need IE11 browser check, use this function.)
   *
   * @returns {boolean} true or false.
   */
  isIE11() {
    return navigator.appName === 'Netscape' && navigator.userAgent.search('Trident') > -1;
  },

  /**
   * Returns IE Browser Version. (You can check to version lower than or equal to IE 10.)
   *
   * @returns {number} IE Browser version. If has not version returns -1.
   */
  getVersion() {
    let rv = -1;
    if (navigator.appName === 'Microsoft Internet Explorer') {
      if (IEVersionCheckRegExp.exec(navigator.userAgent) != null) {
        rv = parseFloat(RegExp.$1);
      }
    }
    return rv;
  },

  /**
   * Returns whether the "userAgent" string contains a "Trident version".
   *
   * @returns {boolean} true or false.
   */
  hasTridentVersion() {
    return navigator.userAgent.match(IETridentCheckRegExp) !== null;
  }
};

export default {
  /**
   * Trident Version exists when IE browser version is higher than 7 and Compatibility View is enabled.
   * But IE browser version less than or equal to 7 does not have a Trident Version.
   *
   * @returns {boolean} true or false.
   */
  isSupported() {
    return !(IEChecker.isIE() && IEChecker.getVersion() <= 7 && IEChecker.hasTridentVersion() === false);
  },

  isSupportXMLRequest() {
    return !(IEChecker.isIE() && IEChecker.getVersion() <= 9) || this.isIE11();
  },

  isIE8() {
    return IEChecker.isIE() && IEChecker.getVersion() === 8;
  },

  isIE11: IEChecker.isIE11,

  isLowerThanIE10() {
    return (IEChecker.isIE() && IEChecker.getVersion() < 10) && !this.isIE11();
  },
};
