import CompatibilityChecker from './utils/CompatibilityChecker';

export default {
  /**
   * If window event is triggered before loaded CoupangWebLog framework, adding event to each element is not working.
   * This function to help checking if window.load is triggered already.
   *
   * @param fn
   */
  add(type: string, fn: EventListener) {
    if (document.readyState === 'complete' || (document.readyState !== 'loading' && CompatibilityChecker.isIE8())
    || (type === 'DOMContentLoaded' && document.readyState === 'interactive')) {
      window.setTimeout(fn);
    } else {
      window.addEventListener(type, fn);
    }
  }
}
