import { onFCP } from 'web-vitals';
import { Metric } from 'web-vitals';
import AppLogRequester from '../../requesters/AppLogRequester';
import WebLogRequester from '../../requesters/WebLogRequester';
import CommonParameter from '../../parameters/CommonParameter';
import PerformanceLog from '../PerformanceLog/PerformanceLog';

export default class FCPLog extends PerformanceLog {
  schemaID: number;
  schemaVersion: number;
  fcp: number;

  constructor(webLogRequester: WebLogRequester, appLogRequester: AppLogRequester) {
    super(webLogRequester, appLogRequester);
    this.schemaID = 10797;
    this.schemaVersion = 2;
    this.fcp = -1;
  }

  /**
   * TTI Initializer
   *
   * @param initFields {
   *     pageName {string} - page name
   *     platform {string} - android|ios|web|mweb
   *     platformType {string} - native|browser|...
   *     screenType {string} - device screen type
   *     extra {object} - extra fields need added into the log
   *     async {boolean} - true async mode, false sync mode
   *     calcType {string} - tti calcuation type, 'default' means tti value will include redirect time, 'excludeRedirect' means tti value will exclude redirect time.
   * }
   */
  init(initFields: PerfInitFields): void {
    const {
      pageName,
      platform,
      platformType,
      screenType,
      extra,
      async = false,
      applicationId = 'no_applicationId_assigned',
    } = initFields;

    this.commonFields = this.makeCommonFields({
      domain: 'fcp',
      pageName,
      platformType,
      applicationId,
    });

    this.baseOption = {
      screenType,
      extra,
      async,
    };

    this.commonParameter = new CommonParameter().setPlatform(platform);
    this.onFCP();
  }

  private onFCP() {
    onFCP((param: Metric) => {
      console.log('test web-vitals: ', 'fcp -- ', param);
      this.fcp = Math.round(param.value) || -1;
      this.goSubmit();
    })
  }

  private goSubmit(optionFields: OptionFields = {}): void {
    super.submit('fcp', this.fcp, optionFields);
  }
}
