import { onFID } from 'web-vitals';
import { Metric } from 'web-vitals';
import AppLogRequester from '../../requesters/AppLogRequester';
import WebLogRequester from '../../requesters/WebLogRequester';
import CommonParameter from '../../parameters/CommonParameter';
import PerformanceLog from '../PerformanceLog/PerformanceLog';

export default class FIDLog extends PerformanceLog {
  schemaID: number;
  schemaVersion: 1;
  fid: number;

  constructor(webLogRequester: WebLogRequester, appLogRequester: AppLogRequester) {
    super(webLogRequester, appLogRequester);
    this.schemaID = 11779;
    this.schemaVersion = 1;
    this.fid = -1;
  }

  /**
   * TTI Initializer
   *
   * @param initFields {
   *     pageName {string} - page name
   *     platform {string} - android|ios|web|mweb
   *     platformType {string} - native|browser|...
   *     screenType {string} - device screen type
   *     extra {object} - extra fields need added into the log
   *     async {boolean} - true async mode, false sync mode
   *     calcType {string} - tti calcuation type, 'default' means tti value will include redirect time, 'excludeRedirect' means tti value will exclude redirect time.
   * }
   */
  init(initFields: PerfInitFields): void {
    const {
      pageName,
      platform,
      platformType,
      screenType,
      extra,
      async = false,
      applicationId = 'no_applicationId_assigned',
    } = initFields;

    this.commonFields = {
      domain: 'fid',
      logCategory: 'system',
      logType: 'performance',
      eventName: 'web_latency_track_log',
      pageName,
      platformType,
      applicationId,
      ixid: this.generateUUID(),
    };

    this.baseOption = {
      screenType,
      extra,
      async,
    };

    this.commonParameter = new CommonParameter().setPlatform(platform);
    this.onFID();
  }

  private onFID() {
    onFID((param: Metric) => {
      this.fid = Math.round(param.value) || -1;
      this.goSubmit();
    }, { reportAllChanges: true })
  }

  private goSubmit(): void {
    super.submit('fid', this.fid, {});
  }
}
