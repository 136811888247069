/**
 * This class provides parameters that are commonly used in PC and Mobile Web.
 */
export default class CommonWebParameter {
  private pvid: string;
  private rvid: string;
  private url: string;
  private referrer: string;

  constructor() {
    const currentUrl = window.location.href;
    const referrerUrl = document.referrer || '';

    this.setPvId(this.generatePvId());
    this.setRvId(''); // Todo: rvid를 전달할 수 있는 방안이 마련된 후 작업하도록 한다.
    this.setReferrer(referrerUrl);
    this.setUrl(currentUrl);
  }

  /**
   * Returns JSON object of CommonWebParameter
   *
   * @returns {{web: {pvid: string, rvid: string, url: string, referrer: string}}}
   */
  getJSON(): object {
    const {
      pvid,
      rvid,
      url,
      referrer
    } = this;

    return {
      web: {
        pvid,
        rvid,
        url,
        referrer
      }
    };
  }

  /**
   * Generate pvid. (uuid_v4)
   *
   * @returns {string} Returns value of pvid
   */
  private generatePvId() {
    let uuid = '';
    let i;

    for (i = 0; i < 32; i++) {
      const random = Math.random() * 16 | 0;

      if (i === 8 || i === 12 || i === 16 || i === 20) {
        uuid += '-';
      }
      uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(16);
    }

    return uuid;
  }

  /**
   * Set the pvid.
   *
   * @param rvid {string} Returns pvid
   */
  private setPvId(pvid: string) {
    this.pvid = pvid;
  }

  /**
   * Set rvid.
   *
   * @param rvid {string} Returns rvid
   */
  private setRvId(rvid: string) {
    this.rvid = rvid;
  }

  /**
   * Set the URL.
   *
   * @param url {string} Returns url
   */
  private setUrl(url: string) {
    this.url = url;
  }

  /**
   * Set Referrer.
   *
   * @param referrer {string} Returns referrer
   */
  private setReferrer(referrer: string) {
    this.referrer = referrer;
  }
}