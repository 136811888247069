import {naturalize} from '../utils/NumberUtil';

/**
 * Provides the Network Releated Parameters for Landing Request
 */
export default class NetworkParameter {
  /**
   * Get Network Related Parameters for Landing Request
   * @refer https://www.w3.org/TR/navigation-timing
   * @returns {object}
   */
  getNetworkParameters(): object {
    if (!window.performance || !window.performance.timing) {
      return {};
    }

    const timing = window.performance.timing;

    return {
      navigationStart: naturalize(timing.navigationStart),
      redirectStart: naturalize(timing.redirectStart),
      fetchStart: naturalize(timing.fetchStart),
      domainLookupStart: naturalize(timing.domainLookupStart),
      connectStart: naturalize(timing.connectStart),
      secureConnectionStart: naturalize(timing.secureConnectionStart),
      requestStart: naturalize(timing.requestStart),
      responseStart: naturalize(timing.responseStart),
      // for some legacy browser (lower IE version), redirectEnd and redirectStart may be always 0, 
      // so we could use fetchStart - navigationStart as direct time.
      redirect: naturalize(timing.redirectEnd - timing.redirectStart) || naturalize(timing.fetchStart - timing.navigationStart),
      // domain lookup time
      dns: naturalize(timing.domainLookupEnd - timing.domainLookupStart),
      // ssl connect time
      ssl: naturalize(timing.connectEnd - timing.secureConnectionStart),
      // total connect time including ssl connect time
      connect: naturalize(timing.connectEnd - timing.connectStart),
      // server process time (include network time)
      request: naturalize(timing.responseStart - timing.requestStart),
      // response time (download time)
      response: naturalize(timing.responseEnd - timing.responseStart)
    }
  }
  
}
